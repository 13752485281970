import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import Heart from "../../../components/marketplace/home/excluSlider/exclu/picto/Heart";
import { firstToUpperCase } from "../../../components/marketplace/profil/controllers/shortTitleCtrl";
import { swithDarkColors } from "../categoryPage/controllers/themeCtrl";
import theme from "../../../theme";
import Cartouche from "../../../components/marketplace/home/excluSlider/exclu/Cartouche";
import ButtonContainer from "./ButtonContainer";
import AllVolumes from "./AllVolumes";
import VolumesOrChapters from "../../../components/marketplace/productSheet/VolumesOrChapters";
import { calculLikes } from "../categoryPage/controllers/algoCtrl";
import {
  useProductsState,
  useTvaState,
  useUserState,
} from "../categoryPage/controllers/stateCtrl";
import { REACT_APP_API_HOST } from "../../../service";
import { Helmet } from "react-helmet-async";
import { useCensored } from "../categoryPage/controllers/stateCtrl";
import img18 from "../../../components/marketplace/home/shopCard/image/18.png";
import { getCurrentVolume } from "../../../lib/state/actions";
import { volumePrice } from "../../../components/marketplace/productSheet/controller/cartController";
import { allReplace } from "../../../components/marketplace/reader/lib/readerCtrl";

const ProductSheet = () => {
  const [product, setProduct] = useState("");
  const [firstVolume, setFirstVolume] = useState({});
  const { id } = useParams();
  const isCensored = useCensored(useSelector);
  const { userEbooks } = useUserState(useSelector);

  const products = useProductsState(useSelector);
  const tva = useTvaState(useSelector);

  const dispatch = useDispatch();

  //move to top when mount component
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (products && products.length > 0) {
      const article = products.find((a) => a._id === id);
      if (article) {
        setProduct(article);
      }
    }
  }, [id, products]);

  useEffect(() => {
    if (product && product.volumes && product.volumes.length > 0) {
      dispatch(getCurrentVolume(product.volumes[0], setFirstVolume));
    }
  }, [product, dispatch]);

  const volumeIsSold = () => {
    if (
      product &&
      product.volumes.length > 0 &&
      userEbooks &&
      userEbooks.length > 0
    ) {
      let firstVolume = product.volumes[0];
      let firstVolumeIsSold = userEbooks.find((vol) => vol._id === firstVolume);
      if (firstVolumeIsSold) {
        return true;
      }
      return false;
    }
    return false;
  };

  let object = {
    "@context": "https://schema.org",
    "@type": "DataFeed",
    dataFeedElement: [
      {
        "@context": "https://schema.org",
        "@type": "Book",
        "@id": `https://mangaebook.com/item/${id}`,
        url: `https://mangaebook.com/item/${id}`,
        name: product?.frenchTitle,
        author: {
          "@type": "Person",
          name: product?.author && product?.author[0],
        },
        workExample: [
          {
            "@type": "Book",
            "@id": `https://mangaebook.com/item/${id}`,
            isbn: firstVolume?.ISBN,
            bookEdition: product?.frenchPublisher,
            bookFormat: "https://schema.org/EBook",
            inLanguage: "fr",
            url: `https://mangaebook.com/item/${id}`,
            datePublished: firstVolume?.volumeReleaseDate,
            potentialAction: {
              "@type": "ReadAction",
              target: {
                "@type": "EntryPoint",
                urlTemplate: `/liseuse/${product?._id}/${firstVolume._id}/${
                  firstVolume.chapters && firstVolume.chapters[0]._id
                }/extract`,
                actionPlatform: [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/AndroidPlatform",
                  "https://schema.org/IOSPlatform",
                ],
              },
              expectsAcceptanceOf: {
                "@type": "Offer",
                category: "purchase",
                price: volumePrice(firstVolume, tva)
                  ?.price.toString()
                  .replaceAll(".", ","),
                priceCurrency: "EUR",
                eligibleRegion: {
                  "@type": "Country",
                  name: "FR",
                },
              },
            },
          },
        ],
      },
    ],
    dateModified: product?.lastModify + "T00:00:00",
  };

  const mySchema = JSON.stringify(object);

  const makeJSONLD = () => {
    return { __html: mySchema };
  };

  return (
    <>
      {product && (
        <>
          <Helmet>
            <title>{`Mangaebook - ${product.category} numérique ${product?.frenchTitle}`}</title>
            <meta
              name="title"
              content={`Mangaebook - ${product.category} numérique ${product?.frenchTitle}.`}
            />
            <meta
              id="og-title"
              property="og:title"
              content={`Mangaebook - ${product.category} numérique ${product?.frenchTitle}.`}
            />
            <meta
              name="description"
              content={`Présentation | Extrait | Lire en ligne | Télécharger ${product?.frenchTitle} des éditions ${product?.frenchPublisher}.`}
            />
            <meta
              property="og:description"
              content={`Présentation | Extrait | Lire en ligne | Télécharger ${product?.frenchTitle} des éditions ${product?.frenchPublisher}.`}
            />
            <meta
              name="prerender-header"
              content={`Location: https://www.mangaebook.com/item/${id}`}
            ></meta>
            <meta
              property="og:url"
              content={`https://www.mangaebook.com/item/${id}`}
            />
            <meta
              id="og-image"
              property="og:image"
              content={REACT_APP_API_HOST + product?.imageURL}
            />
            <meta property="og:image:type" content="image/jpeg" />
          </Helmet>
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={makeJSONLD()}
          />
          <H1 className="segoesc">
            <Link to="/">MangaeBook /</Link>
            <SpanCat>
              <Link to={`/category/${product.category}`}>
                {product.category}{" "}
              </Link>
            </SpanCat>
            /{product?.frenchTitle}
          </H1>
          {product.ended && product.volumes.length === 1 ? (
            <Wrapper className="oneShot">
              <RowContainer>
                <InfoContainer>
                  <Link
                    to={
                      volumeIsSold()
                        ? !isCensored &&
                          `/liseuse/${allReplace(product.frenchTitle)}/${
                            product._id
                          }/${firstVolume._id}/${
                            firstVolume.chapters && firstVolume.chapters[0]._id
                          }`
                        : !isCensored &&
                          `/liseuse/${allReplace(product.frenchTitle)}/${
                            product._id
                          }/${firstVolume._id}/${
                            firstVolume.chapters && firstVolume.chapters[0]._id
                          }/extract`
                    }
                  >
                    <Img
                      src={
                        !isCensored
                          ? REACT_APP_API_HOST + product?.imageURL
                          : img18
                      }
                      alt={`couverture ${product.frenchTitle}`}
                    />
                  </Link>
                </InfoContainer>
                <InfoContainer className="oneShot">
                  <H2 className="montserratSemiBold">{product?.frenchTitle}</H2>
                  {product.ended ? (
                    product.volumes.length > 1 ? (
                      <Extract className="segoesc">Série complète</Extract>
                    ) : (
                      <Extract className="segoesc">One-shot</Extract>
                    )
                  ) : (
                    <Extract className="segoesc">Série en cours...</Extract>
                  )}
                  <Tags
                    className="montserratSemiBold oneShot"
                    color={swithDarkColors(product.category, theme)}
                  >
                    <TagsContainer className="oneShot">
                      <Cartouche cat={product.category} />
                    </TagsContainer>
                    {calculLikes(product) > 0 && (
                      <LikesContainer className="oneShot">
                        <Heart small={true} />
                        <Like className="segoesc">{calculLikes(product)}</Like>
                      </LikesContainer>
                    )}
                    <TagsContainer className="oneShot">
                      {product.tags?.map((t) => (
                        <Span key={t}>
                          <Link to={`/category/${product.category}/tag/${t}`}>
                            #{firstToUpperCase(t)}
                          </Link>
                        </Span>
                      ))}
                    </TagsContainer>
                  </Tags>
                  {
                    <Grid className="montserrat oneShot">
                      <Name>
                        <LegendBis>Auteur:</LegendBis>
                        {product.author?.map((a) => (
                          <span key={a}>{firstToUpperCase(a)}</span>
                        ))}
                      </Name>

                      <Name>
                        <LegendBis>Illustrateur:</LegendBis>
                        {firstToUpperCase(product?.illustrator)}
                      </Name>

                      <Name>
                        <LegendBis>Édition originale:</LegendBis>
                        {firstToUpperCase(product?.originalPublisher)}
                      </Name>

                      <Name>
                        <LegendBis>Synopsis:</LegendBis>
                        <span className="montserratSemiBold cesure">
                          {product?.synopsis}
                        </span>
                      </Name>
                    </Grid>
                  }
                </InfoContainer>
              </RowContainer>
              {product.paperURL && (
                <ButtonContainer
                  link={product.paperURL ? product.paperURL : null}
                  message="volume papier"
                  newTab={true}
                />
              )}
              {product.volumes.length > 0 && !isCensored && (
                <VolumesOrChapters product={product} />
              )}
            </Wrapper>
          ) : (
            <Wrapper>
              <InfoContainer>
                <Link
                  to={
                    volumeIsSold()
                      ? `/liseuse/${allReplace(product.frenchTitle)}/${
                          product._id
                        }/${product.volumes[0]}/${
                          firstVolume.chapters && firstVolume.chapters[0]._id
                        }`
                      : `/liseuse/${allReplace(product.frenchTitle)}/${
                          product._id
                        }/${product.volumes[0]}/${
                          firstVolume.chapters && firstVolume.chapters[0]._id
                        }/extract`
                  }
                >
                  <Img
                    src={
                      !isCensored
                        ? REACT_APP_API_HOST + product?.imageURL
                        : img18
                    }
                    alt={`couverture ${product.frenchTitle}`}
                  />
                </Link>
                <H2 className="montserratSemiBold">{product?.frenchTitle}</H2>
                {product.ended ? (
                  product.volumes.length > 1 ? (
                    <Extract className="segoesc">Série complète</Extract>
                  ) : (
                    <Extract className="segoesc">One-shot</Extract>
                  )
                ) : (
                  <Extract className="segoesc">Série en cours...</Extract>
                )}

                <Tags
                  className="montserratSemiBold"
                  color={swithDarkColors(product.category, theme)}
                >
                  <TagsContainer className="cartouche">
                    <Cartouche cat={product.category} />
                  </TagsContainer>
                  <TagsContainer>
                    {product.tags?.map((t) => (
                      <Span key={t}>
                        <Link to={`/category/${product.category}/${t}`}>
                          #{firstToUpperCase(t)}
                        </Link>
                      </Span>
                    ))}
                  </TagsContainer>
                </Tags>

                {
                  <Grid className="montserrat">
                    {calculLikes(product) > 0 && (
                      <LikesContainer>
                        <Heart small={true} />
                        <Like className="segoesc">{calculLikes(product)}</Like>
                      </LikesContainer>
                    )}
                    <Name>
                      <LegendBis>Auteur:</LegendBis>
                      {product.author?.map((a) => (
                        <span key={a}>{firstToUpperCase(a)}</span>
                      ))}
                    </Name>

                    <Name>
                      <LegendBis>Illustrateur:</LegendBis>
                      {firstToUpperCase(product?.illustrator)}
                    </Name>

                    <Name>
                      <LegendBis>Édition originale:</LegendBis>
                      {firstToUpperCase(product?.originalPublisher)}
                    </Name>

                    <Name>
                      <LegendBis>Synopsis:</LegendBis>
                      <span className="montserratSemiBold cesure">
                        {product?.synopsis}
                      </span>
                    </Name>
                    {product.paperURL && (
                      <ButtonContainer
                        link={product.paperURL ? product.paperURL : null}
                        message="volume papier"
                        newTab={true}
                      />
                    )}
                  </Grid>
                }
              </InfoContainer>
              {product.volumes.length > 0 && !isCensored && (
                <VolumesOrChapters product={product} />
              )}
            </Wrapper>
          )}
        </>
      )}

      <Container>
        {/*!isCensored &&*/ <AllVolumes product={product} productId={id} />}
      </Container>
    </>
  );
};

const H1 = styled.h1`
  position: absolute;
  top: 70px;
  left: 30px;
  font-size: 14px;
  @media (max-width: 1150px) {
    left: 10px;
  }
`;

const SpanCat = styled.strong`
  margin-left: 6px;
  text-transform: uppercase;
`;

const Wrapper = styled.article`
  box-sizing: border-box;
  margin: 0 auto;
  background-color: white;
  max-width: 1200px;
  margin-top: 60px;
  padding: 20px 20px;
  border-radius: 5px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.15);
  display: flex;

  @media (max-width: 1200px) {
    border-radius: 0px;
  }
  @media (max-width: 815px) {
    flex-direction: column;
    align-items: center;
  }
  @media (max-width: 500px) {
    padding: 20px 5px;
  }
  &.oneShot {
    flex-direction: column;
  }
`;

const RowContainer = styled.div`
  display: flex;
  @media (max-width: 815px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Container = styled.section`
  max-width: 1200px;
  margin: 0 auto;
`;

const Img = styled.img`
  width: 300px;
  display: block;
  margin: 0 auto;
`;

const Extract = styled.span`
  font-size: 16px;
  text-align: center;
  margin-top: 17px;
  transform: rotate(-4deg);
`;

const InfoContainer = styled.div`
  box-sizing: border-box;
  width: 300px;
  display: flex;
  flex-direction: column;
  &.oneShot {
    width: 100%;
    padding-left: 15px;
  }
  @media (max-width: 815px) {
    width: 100%;
  }
`;

const H2 = styled.h2`
  text-transform: uppercase;
  font-size: 15px;
  text-align: center;
  margin-top: 15px;
`;

const LikesContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  &.oneShot {
    align-items: center;
    padding-bottom: 0;
    padding-right: 15px;
  }
`;

const Like = styled.span`
  padding-left: 5px;
  font-size: 12px;
  font-weight: bold;
`;

const Tags = styled.div`
  margin-top: 17px;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
  color: ${(props) => props.color};
  font-size: 14px;
  @media (max-width: 815px) {
    width: 100%;
    flex-direction: row;
    justify-content: center;
  }
  &.oneShot {
    width: 100%;
    flex-direction: row;
    justify-content: center;
  }
`;

const TagsContainer = styled.div`
  text-align: center;
  margin: 0 auto;
  @media (max-width: 815px) {
    margin: unset;
    padding-right: 15px;
    &:last-child {
      padding-right: 0px;
    }
  }
  &.cartouche {
    padding-bottom: 5px;
    @media (max-width: 815px) {
      padding-bottom: 0;
    }
  }
  &.oneShot {
    padding-right: 15px;
    margin: 0;
    text-align: left;
    &:last-child {
      padding-right: 0px;
    }
  }
`;

const Grid = styled.div`
  box-sizing: border-box;
  font-size: 14px;
  width: 100%;
  margin: 0 auto;
  padding: 0 10px;
  &.oneShot {
    width: 100%;
    padding: 0;
  }
`;

const LegendBis = styled.span`
  display: block;
  color: ${(props) => props.theme.primary.black};
  padding-bottom: 5px;
`;

const Name = styled.p`
  color: ${(props) => props.theme.primary.offBlack};
  margin-bottom: 15px;
  & span {
    padding-right: 10px;
    font-size: 14px;
    &:last-child {
      padding-right: 0px;
    }
    &.cesure {
      word-break: break-word;
    }
  }
  &:last-child {
    margin-bottom: 0px;
  }
`;

const Span = styled.p`
  display: inline-block;
  margin-top: 2px;
  margin-right: 8px;
  word-break: keep-all;
  &:last-child {
    margin-right: 0px;
  }
`;

export default ProductSheet;
